export const TABLES_LIST = [
  'myDevices',
  'newDeviceSetup',
  'appSetup',
  'deviceView',
  'myCollection',
  'deviceSettings',
  'androidUpdatesDeployment',
  'deviceUsers',
]
export const MANDATORY_COLUMNS = {
  myDevices: [],
  newDeviceSetup: [],
  appSetup: [],
  deviceView: [],
  myCollection: [],
  deviceSettings: [],
  androidUpdatesDeployment: [],
  deviceUsers: [],
}
export const OPT_COLUMNS = {
  myDevices: [
    { label: 'Model', value: 'model' },
    { label: 'Device Name', value: 'deviceName' },
    { label: 'Device Group', value: 'groups' },
    { label: 'OS Version', value: 'formattedOsVersion' },
    { label: 'Security Patch Date', value: 'securityPatch' },
    { label: 'Serial Number', value: 'serialNumber' },
    { label: 'IMEI', value: 'imei' },
    { label: 'Battery Health', value: 'batteryType' },
    { label: 'Last Connected', value: 'lastConnected' },
    { label: 'IP Address', value: 'ipAddress' },
    { label: 'Actions', value: 'actions' },
  ],
  newDeviceSetup: [
    { label: 'Setup Name', value: 'name' },
    { label: 'Setup Category', value: 'category' },
    { label: 'Android Updates', value: 'fotaEnabled' },
    { label: 'Device Group Assignment', value: 'groups' },
    { label: 'Description', value: 'description' },
    { label: 'Date Modified', value: 'updatedDateTime' },
    { label: 'Actions', value: 'actions' },
  ],
  appSetup: [
    { label: 'Setup Name', value: 'name' },
    { label: 'Setup Status', value: 'deploymentStatus' },
    { label: 'App Name', value: 'appName' },
    { label: 'App Version', value: 'appVersion' },
    { label: 'Hosting Location', value: 'hostingLocation' },
    { label: 'Description', value: 'description' },
    { label: 'Data Modified', value: 'updatedDateTime' },
    { label: 'Actions', value: 'actions' },
  ],
  deviceView: [
    { label: 'Model', value: 'model' },
    { label: 'Update Status', value: 'updateStatus' },
    { label: 'Device Name', value: 'deviceName' },
    { label: 'Device Group', value: 'groups' },
    { label: 'OS Version', value: 'formattedOsVersion' },
    { label: 'Security Patch Date', value: 'securityPatch' },
    { label: 'OS Build Id', value: 'buildId' },
    { label: 'MX Version', value: 'mxVersion' },
    { label: 'Serial Number', value: 'serialNumber' },
    { label: 'Actions', value: 'actions' },
  ],
  myCollection: [
    { label: 'App Name', value: 'appName' },
    { label: 'Hosting Location', value: 'hostingLocation' },
    { label: 'App Version', value: 'appVersion' },
    { label: 'Developer', value: 'developer' },
    { label: 'Package Name', value: 'appPackage' },
    { label: 'App Description', value: 'appDescription' },
    { label: 'Actions', value: 'actions' },
  ],
  deviceSettings: [
    { label: 'Setting Name', value: 'name' },
    { label: 'Setting Status', value: 'deploymentStatus' },
    { label: 'Description', value: 'description' },
    { label: 'Last Updated', value: 'updatedDateTime' },
    { label: 'Actions', value: 'actions' },
  ],
  androidUpdatesDeployment: [
    { label: 'Deployment Name', value: 'name' },
    { label: 'Status', value: 'finalStatus' },
    { label: 'Target Device Count', value: 'totalDevices' },
    { label: 'Results', value: 'results' },
    { label: 'Description', value: 'description' },
    { label: 'Completed On', value: 'completedOn' },
    { label: 'Actions', value: 'actions' },
  ],
  deviceUsers: [
    { label: 'Employee Id', value: 'employeeId' },
    { label: 'Security Type(s)', value: 'securityTypes' },
    { label: 'User Type', value: 'userType' },
    { label: 'Valid Through', value: 'validThrough' },
    { label: 'Created On', value: 'createdOn' },
    { label: 'Created By Device', value: 'createdByDevice' },
    { label: 'Created By S/N', value: 'createdByDeviceSerialNumber' },
    { label: 'Last Log-In', value: 'lastLogIn' },
    { label: 'Last Log-In Device', value: 'lastLogInDevice' },
    { label: 'Last Log-In S/N', value: 'lastLogInDeviceSerialNumber' },
    { label: 'Enrollment Id', value: 'enrollmentId' },
    { label: 'Actions', value: 'actions' },
  ],
}
export const HIDDEN_COLUMNS = {
  myDevices: [
    { label: 'Last Connected', value: 'lastConnected' },
    { label: 'IP Address', value: 'ipAddress' },
  ],
  newDeviceSetup: [],
  appSetup: [],
  deviceView: [],
  myCollection: [],
  deviceSettings: [],
  androidUpdatesDeployment: [],
  deviceUsers: [
    { label: 'Valid Through', value: 'validThrough' },
    { label: 'Created On', value: 'createdOn' },
    { label: 'Created By Device', value: 'createdByDevice' },
    { label: 'Created By S/N', value: 'createdByDeviceSerialNumber' },
    { label: 'Last Log-In Device', value: 'lastLogInDevice' },
    { label: 'Enrollment Id', value: 'enrollmentId' },
  ],
}
